<template>
  <div class="Page-Wrapper">
    <SortingWithFiltersPreset
      class="SFPRestyle"
      title="Администрирование новостей"
      searchPlaceholder="Поиск"
      :searchAttribures="['title', 'desc']"
      :originalArray="News.List"
      :setNewArrayCallback="handleArrayUpdate"
      :sortMethodCallback="handleSort"
      :filtersButtons="FiltersButtons"
      :sortOptions="[{ text: 'Сортировка по умолчанию', val: 'default' }]"
      :filterButtonModeServer="true"
      :callbackForFilterButton="handleFilter"
      :postFiltersDataChanges="handleFilterChanges"
      :setFiltersData="{
        button: Filters.button,
        search: Filters.search,
        sorting: Filters.sorting,
      }"
      :key="'SortingFiltersSearch-' + news_hash"
    >
      <template v-slot:content>
        <span
          class="Offer-Button-Next"
          style="width: fit-content;"
          @click="createModal"
          >Создать новость</span
        >
      </template>
    </SortingWithFiltersPreset>

    <NewsList
      class="NewsRestyle noselect"
      :useReadyList="true"
      :readyList="News.List_Filtered"
      :key="`NewsFiltered-${news_filtered_hash}`"
      :callbackClick="handleClickOnCard"
    />

    <NewStyledModal
      class="Styled-Modal"
      :showModal="show_modal"
      :hideModal="closeModal"
      :key="show_modal"
    >
      <template v-slot:ModalContent>
        <div class="Offer-Modal" v-if="PostData != null">
          <span class="Offer-Modal-Title">
            {{ isEdit ? "Редактирование новости" : "Создание новости" }}
          </span>

          <div class="Offer-Modal-Content">
            <div class="Input-Floating-Label">
              <input
                class="Input-styled"
                type="text"
                placeholder="Введите название новости"
                autocomplete="off"
                maxlength="64"
                v-model="PostData.title"
              />
              <span class="Input-Placeholder noselect">Название</span>
              <span class="Input-Text-Length noselect">{{
                64 - PostData.title.length
              }}</span>
            </div>

            <div class="Input-Floating-Label">
              <div
                class="QEditor QEditorFixHeight"
                style="position: relative; width: 640px; height: 500px;"
                :key="PostData.id"
              >
                <QuillEditor
                  ref="QuillEditor"
                  toolbar="full"
                  theme="snow"
                  contentType="html"
                  :content="PostData.desc"
                  @textChange="handleEditorEditChanges($event)"
                  @ready="handleEditorEditReady()"
                />
              </div>
            </div>

            <div class="Input-Floating-Label">
              <div class="checkbox_agreement">
                <input
                  type="checkbox"
                  id="advert_checkbox"
                  name="advert_checkbox"
                  v-model="PostData.archieve"
                  style="width: 24.4px !important;"
                />
                <label for="advert_checkbox">Архив</label>
              </div>
            </div>

            <div class="Input-Floating-Label">
              <div class="checkbox_agreement">
                <input
                  type="checkbox"
                  id="advert_checkbox"
                  name="advert_checkbox"
                  v-model="PostData.needButton"
                  style="width: 24.4px !important;"
                />
                <label for="advert_checkbox">Нужна кнопка</label>
              </div>
            </div>

            <div
              class="Input-Floating-Label"
              :key="PostData.needButton"
              v-if="PostData.needButton"
            >
              <input
                class="Input-styled"
                type="text"
                placeholder="Введите название кнопки"
                autocomplete="off"
                maxlength="32"
                v-model="PostData.cta_name"
              />
              <span class="Input-Placeholder noselect">Название кнопки</span>
              <span class="Input-Text-Length noselect">{{
                32 - PostData.cta_name.length
              }}</span>
            </div>

            <div
              class="Input-Floating-Label"
              :key="PostData.needButton"
              v-if="PostData.needButton"
            >
              <input
                class="Input-styled"
                type="text"
                placeholder="Укажите ссылку для кнопки"
                autocomplete="off"
                maxlength="300"
                v-model="PostData.cta_link"
              />
              <span class="Input-Placeholder noselect">Ссылка кнопки</span>
            </div>

            <div
              class="Input-Floating-Label"
              :key="PostData.needButton"
              v-if="PostData.needButton"
              style="width: 100%; height: 0px; margin-bottom: 16px;"
            ></div>

            <div class="Input-Floating-Label" :key="isEdit" v-if="isEdit">
              <input
                disabled
                class="Input-styled"
                type="text"
                :value="
                  `https://students.forus.ru/beta/news-details?post=${PostData.link_name}`
                "
              />
              <span class="Input-Placeholder noselect"
                >Ссылка на пост (Только просмотр)</span
              >
            </div>

            <div class="Input-Floating-Label">
              <div
                class="dropzone-container"
                :style="isDragging && 'border-color: green;'"
                @dragover="dragover"
                @dragleave="dragleave"
                @drop="drop"
              >
                <input
                  type="file"
                  multiple
                  name="file"
                  id="fileInput"
                  class="hidden-input"
                  @change="onChange"
                  ref="file"
                />

                <label for="fileInput" class="file-label">
                  <div class="noselect" style="margin: auto;">
                    Нажмите на эту область или перетащите в нее материалы
                  </div>
                </label>
              </div>
            </div>

            <div class="Input-Floating-Label">
              <div
                class="preview-container"
                v-if="files.length >= 1 || PostData.NewsMaterials?.length >= 1"
              >
                <div
                  v-for="file in PostData.NewsMaterials"
                  :key="file.url_file"
                  class="preview-card"
                >
                  <span class="FileName">{{
                    getFilenameWithExt(file.url_file)
                  }}</span>

                  <a
                    :href="file.url_file"
                    target="_blank"
                    class="Preview-Img"
                    :style="getStyleFromUploadedFile(file.url_file)"
                  >
                  </a>

                  <div
                    class="Remove-Button"
                    @click="
                      () => {
                        deleteMaterial(file.id);
                      }
                    "
                  ></div>
                </div>

                <div
                  v-for="file in files"
                  :key="file.name"
                  class="preview-card"
                >
                  <div class="Preview-Img" :style="generateURL(file)">
                    <div
                      class="Remove-Button"
                      @click="remove(files.indexOf(file))"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="Offer-Modal-Buttons noselect">
            <span class="Offer-Button-Prev" @click="closeModal">Отмена</span>
            <span class="Offer-Button-Next" @click="saveModal">{{
              isEdit ? "Cохранить" : "Создать"
            }}</span>
          </div>
        </div>
      </template>
    </NewStyledModal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import {
  getNewsByFilters,
  postDetails,
  deletePostMaterial,
  uploadPostMaterials,
  updatePost,
  createPost,
} from "@/api/news.js";

import NewsList from "@/components/Profile/NewsList.vue";
import SortingWithFiltersPreset from "@/components/CustomElements/SortingWithFiltersPreset.vue";
import NewStyledModal from "@/components/CustomElements/NewStyledModal.vue";
import { QuillEditor } from "@vueup/vue-quill";

export default {
  name: "AdminNews",
  components: {
    SortingWithFiltersPreset,
    NewStyledModal,
    NewsList,
    QuillEditor,
  },
  computed: {
    ...mapGetters({
      user: "auth/isLoggedIn",
    }),

    news_hash() {
      let a = this.News.List.map(
        (x, xindx) =>
          `${x.id}_${x?.link_name}_${x?.archieve}_${xindx}_${x?.title}_${x?.desc}`
      );
      return `${this.News.List?.length}-${a.join("-")}`;
    },

    news_filtered_hash() {
      let a = this.News.List_Filtered.map(
        (x, xindx) =>
          `${x.id}_${x?.link_name}_${x?.archieve}_${xindx}_${x?.title}_${x?.desc}`
      );
      return `${this.News.List_Filtered?.length}-${a.join("-")}`;
    },
  },
  data() {
    return {
      Filters: {
        button: "available",
        search: "",
        sorting: "default",
      },
      News: {
        List: [],
        List_Filtered: [],
      },
      FiltersButtons: [
        {
          text: "Доступные",
          val: "available",
        },
        {
          text: "Архив",
          val: "show-only-archieve",
        },
      ],
      PostData: null,

      show_modal: false,
      isEdit: true,
      block_save_button: false,

      isDragging: false,
      files: [],
    };
  },
  async mounted() {
    await this.initialization();
  },
  methods: {
    async initialization() {
      let _news_req = await getNewsByFilters({
        seeArchieve: this.Filters.button == "show-only-archieve" ? true : false,
      });
      this.News.List = _news_req.data;
    },

    handleArrayUpdate(UpdatedArray) {
      this.News.List_Filtered = UpdatedArray;
    },
    handleSort(SortName, Array) {
      return Array;
    },
    handleFilter(val) {
      this.Filters.button = val;
      this.initialization();
    },
    handleFilterChanges(obj) {
      this.Filters.button = obj.button;
      this.Filters.search = obj.search;
      this.Filters.sorting = obj.sorting;
    },

    async handleClickOnCard(link_name) {
      let _detail_req = await postDetails(link_name);

      if (_detail_req.data?.status === "error") {
        alert("Произошла ошибка при загрузке данных");
        return;
      }

      this.isEdit = true;
      this.PostData = _detail_req.data;
      this.PostData.needButton = this.PostData.cta_link != null ? true : false;

      this.show_modal = true;
    },

    handleEditorEditChanges(delta) {
      this.PostData.desc = this.$refs.QuillEditor.getHTML();
    },
    handleEditorEditReady() {
      this.$refs.QuillEditor.setHTML(this.PostData.desc);
    },

    onChange() {
      const self = this;
      let incomingFiles = Array.from(this.$refs.file.files);
      const fileExist = self.files.some((r) =>
        incomingFiles.some(
          (file) => file.name === r.name && file.size === r.size
        )
      );

      console.log(this.$refs.file.files);

      if (fileExist) {
        self.showMessage = true;
        alert("Данные файлы уже загружены.");
      } else {
        self.files.push(...incomingFiles);
      }
    },
    dragover(e) {
      e.preventDefault();
      this.isDragging = true;
    },
    dragleave() {
      this.isDragging = false;
    },
    drop(e) {
      e.preventDefault();
      this.$refs.file.files = e.dataTransfer.files;
      this.onChange();
      this.isDragging = false;
    },
    remove(i) {
      this.files.splice(i, 1);
    },
    generateURL(file) {
      let fileSrc = URL.createObjectURL(file);
      setTimeout(() => {
        URL.revokeObjectURL(fileSrc);
      }, 1000);
      let style = `
      background: url('${fileSrc}');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      background-color: #302E51;
      `;
      return style;
    },
    getStyleFromUploadedFile(mat_url) {
      let style = `
      background: url('${mat_url}');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      `;
      return style;
    },
    getFilenameWithExt(filePath) {
      let getCleanFileNameWithExtension = (filePath) => {
        const parts = filePath.split(/[\\\/]/);
        const fileNameWithExtension = parts[parts.length - 1];
        const fileNameWithoutNumbersBeforeDash = fileNameWithExtension.replace(
          /^\d+-/,
          ""
        );
        return fileNameWithoutNumbersBeforeDash;
      };

      return getCleanFileNameWithExtension(filePath);
    },

    closeModal() {
      this.show_modal = false;
      this.PostData = null;
      this.isDragging = false;
      this.files = [];
      this.block_save_button = false;
    },
    createModal() {
      this.isEdit = false;

      this.PostData = {
        title: "",
        desc: "",
        archieve: false,
        cta_link: null,
        cta_name: "Название кнопки",
        needButton: false,
      };

      this.show_modal = true;
    },

    async deleteMaterial(mat_id) {
      let removeElementById = (arr, id) => {
        const index = arr.findIndex((item) => item.id === id); // Находим индекс элемента с заданным id
        if (index !== -1) {
          arr.splice(index, 1); // Удаляем элемент из массива по найденному индексу
        }
      };

      removeElementById(this.PostData.NewsMaterials, mat_id);

      await deletePostMaterial(mat_id);
      await this.initialization();
    },
    async uploadMaterialsInSprint() {
      let formData = new FormData();

      this.files.forEach((photo) => {
        if (photo) {
          formData.append(photo.name, photo);
        }
      });

      await uploadPostMaterials(this.PostData.id, formData);

      this.files = [];
      return 0;
    },
    async saveModal() {
      this.block_save_button = true;

      if (this.isEdit) {
        if (this.files.length >= 1) {
          await this.uploadMaterialsInSprint();
        }

        await updatePost(this.PostData.id, {
          title: this.PostData.title,
          desc: this.PostData.desc,

          archieve: this.PostData.archieve,

          cta_link: this.PostData.needButton ? this.PostData.cta_link : null,
          cta_name: this.PostData.cta_name,
        });
      } else {
        this.PostData.cta_link = this.PostData.needButton
          ? this.PostData.cta_link
          : null;

        let formData = new FormData();

        this.files.forEach((photo) => {
          if (photo) {
            formData.append(photo.name, photo);
          }
        });

        formData.append("req_data", JSON.stringify(this.PostData));

        await createPost(formData);
      }

      this.closeModal();
      await this.initialization();
    },
  },
};
</script>

<style scoped>
.Page-Wrapper {
  position: relative;
  display: flex;

  margin: 0% auto;
  padding-top: 100px;

  flex-direction: column;
  justify-content: flex-start;
  gap: 44px;

  width: 100%;
  max-width: 1200px;
  min-height: 100svh;

  padding-right: 15px;

  overflow: auto;
}

.NewsRestyle:deep(.NB-Info) {
  display: none;
}
.NewsRestyle {
  cursor: pointer;
}

@media (max-width: 992px) {
  .PageContentWrapper {
    padding-right: 0px;
    padding-bottom: 70px;
  }

  .NewsRestyle:deep(.NB-Desc) {
    -webkit-line-clamp: 4;
  }
  .NewsRestyle:deep(.News-Block) {
    gap: 8px;
  }
  .NewsRestyle:deep(.NB-HeadnDesc) {
    gap: 16px;
  }
}

.Styled-Modal:deep(.SendRequestModal) {
  min-width: 680px;
  width: fit-content;
  background: #1e1b4a;
}
.Offer-Modal {
  position: relative;
  display: flex;

  padding-top: 12px;

  flex-direction: column;
  justify-content: flex-start;
  gap: 24px;

  width: auto;
  height: fit-content;
}

.Offer-Modal-Title {
  position: relative;
  display: block;

  min-width: 320px;
  width: auto;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;

  color: #ffffffe5;
}
.Offer-Modal-Content {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;

  min-width: 320px;
  width: auto;
  height: fit-content;
}

.Input-Floating-Label {
  position: relative;
  display: block;

  min-width: 320px;
  width: auto;
  height: fit-content;
}

.Input-styled {
  position: relative;
  display: block;

  padding: 12px 8px;

  width: 100%;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #ffffffe5;

  transition-timing-function: ease-in;
  transition: 0.2s;

  background-color: transparent;

  outline: 0;
  outline-offset: 0;

  border-radius: 4px;
  border: 1px solid #d3caff40;
}
textarea.Input-styled {
  min-height: 240px;
}

.Input-styled::placeholder {
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #d3caff73;
}
.Input-styled:focus {
  border-color: #656bff;
}
.Input-styled.Input-highligt-error {
  border-color: #ed254e;
}
.Input-styled.Input-highligt-error:focus + .Input-Placeholder,
.Input-styled.Input-highligt-error + .Input-Placeholder {
  color: #ed254e;
}

.Input-Placeholder {
  position: absolute;
  display: block;

  top: 0;
  left: 18px;

  width: auto;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: left;

  transform: translate(0px, -50%);

  transition-timing-function: ease-in;
  transition: 0.2s;

  color: #d3caff4d;
  background: #1e1b4a;
  padding: 0% 2px;
}
.Input-styled:focus + .Input-Placeholder {
  color: #656bff;
}

.Input-Text-Length {
  position: absolute;
  display: block;

  bottom: 0;
  right: 18px;

  width: auto;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: left;

  transform: translate(0px, 50%);

  transition-timing-function: ease-in;
  transition: 0.2s;

  color: #d3caff4d;
  background: #1e1b4a;
  padding: 0% 2px;
}
.Input-styled:focus + .Input-Text-Length {
  color: #656bff;
}

.Offer-Modal-Buttons {
  position: relative;
  display: flex;

  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;

  width: 320px;
  height: fit-content;
}

.Offer-Button-Next {
  position: relative;
  display: block;
  cursor: pointer;

  padding: 12px 16px;

  width: 100%;
  height: fit-content;

  flex-shrink: 1;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: #ffffffe5;

  transition-timing-function: ease-in;
  transition: 0.2s;

  border-radius: 4px;
  background-color: #656bff;
}
.Offer-Button-Prev {
  position: relative;
  display: block;
  cursor: pointer;

  padding: 12px 16px;

  width: fit-content;
  height: fit-content;

  flex-shrink: 0;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: #ffffffe5;

  transition-timing-function: ease-in;
  transition: 0.2s;

  border-radius: 4px;
  background-color: #656bff40;
}
.Offer-Button-Prev:hover {
  background-color: rgba(101, 106, 255, 0.144);
}

.Offer-Text-Before-Confirm {
  position: relative;
  display: block;

  width: 320px;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;

  color: #d3caffb2;
}
.Offer-Text-Before-Confirm .Text-Before-Confirm-Important {
  color: #ffffffe5;
}

.Offer-Modal-Buttons-Child-Wrapper {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;

  width: 320px;
  height: fit-content;
}

.Offer-Text-Balance-After {
  position: relative;
  display: block;

  width: 320px;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
  text-transform: uppercase;

  color: #d3caff4d;
}
.Offer-Text-Error {
  position: relative;
  display: block;

  margin-top: 12px;

  width: 320px;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
  text-transform: none;
  color: #ed254e;
}
</style>

<style scoped>
.HorizontalBlocks {
  position: relative;
  display: flex;

  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;
}

.checkbox_agreement {
  color: #fff;
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  flex-wrap: nowrap;
  margin-top: 0.3rem;
  align-items: center;
}

.checkbox_agreement a {
  font-weight: 500;
  color: #6c59f7;
}

input[type="checkbox"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: transparent;
  /* Not removed via appearance */
  margin: 0;

  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.1em solid #6e6a9a;
  border-radius: 4px;
  padding: 0.7rem;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;

  margin-right: 1rem;
}

input[type="checkbox"]:checked {
  background-image: linear-gradient(221.26deg, #c548ab -3.4%, #635bff 101.08%);
}

input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #fff;

  /* Windows High Contrast Mode */
  background-color: CanvasText;
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}
</style>

<style scoped>
.dropzone-container {
  position: relative;
  display: flex;

  width: 100%;
  height: auto;

  aspect-ratio: 1 / 0.2;

  background: transparent;
  border: 1px solid;
  border-color: #413e74;

  border-radius: 10px;
}
.hidden-input {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
}

.file-label {
  position: relative;
  display: flex;

  padding: 42px 52px;

  width: 100%;
  height: 100%;

  font-family: "Montserrat";
  font-size: 15px;
  font-weight: 400;
  line-height: 23.25px;
  text-align: center;

  cursor: pointer;

  color: #e8e7ec;
}
.preview-container {
  position: relative;
  display: flex;

  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;

  width: 100%;
  height: fit-content;
}
.preview-card {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: center;
  gap: 4px;

  width: calc((100% - 30px) / 4);
  height: fit-content;

  background-color: rgb(48, 46, 81);

  border-radius: 10px;
}

.Preview-Img {
  position: relative;
  display: block;

  width: 100%;
  height: auto;

  aspect-ratio: 1 / 1;

  border-radius: 10px;
}
.FileName {
  position: absolute;
  display: block;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  color: aliceblue;
  font-size: 12px;
  text-align: left;
  padding: 8px;
}

.Remove-Button {
  position: absolute;
  display: block;

  cursor: pointer;

  top: 0;
  right: -5px;

  width: 5px;
  height: 5px;

  background: url("./../../assets/img/profile/RemoveButtonMaterials.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
</style>
